/*     brand Colors              */
/*      light colors         */
/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 30px;
  border: 0;
  position: relative;
  text-align: left;
  margin-bottom: 10px;
  line-height: 8px;
  width: 59px !important;
  height: 22px;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-right: 20px;
  background: rgba(44, 44, 44, 0.2);
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 22px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 12px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  line-height: 11px;
  width: 59px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
  color: #fff;
  background: #d3d930;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
  color: #fff;
  background: #2ca8ff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
  color: #fff;
  background: #598d30;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
  background: #ffb236;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
  color: #fff;
  background: #ff3636;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 22px !important;
  height: 22px !important;
  margin: 0px -11px;
  border-radius: 20px;
  position: absolute;
  float: left;
  top: 0;
  left: 50%;
  padding: 0;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background-color: rgba(23, 23, 23, 0.4);
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -16px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -11px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px;
}

.bootstrap-switch input[type=radio],
.bootstrap-switch input[type=checkbox] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type=radio].form-control,
.bootstrap-switch input[type=checkbox].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -39px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label:before {
  background-color: #FFFFFF;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-red ~ .bootstrap-switch-default {
  background-color: #ff3636;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-orange ~ .bootstrap-switch-default {
  background-color: #ffb236;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-green ~ .bootstrap-switch-default {
  background-color: #598d30;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-brown ~ .bootstrap-switch-default {
  background-color: #d3d930;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-blue ~ .bootstrap-switch-default {
  background-color: #2ca8ff;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
  background-color: #e3e3e3;
}

.bootstrap-switch-off .bootstrap-switch-handle-on {
  opacity: 0;
}

.bootstrap-switch-on .bootstrap-switch-handle-off {
  opacity: 0;
}

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
/*

*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 1px;
}

.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  background-color: #FFFFFF;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: -10px;
  margin-top: -7px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -5px;
  left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background-color: rgba(182, 182, 182, 0.3);
  border-radius: 3px;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #888;
  border-radius: 3px;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  -webkit-transition: 300ms ease 0s;
  -moz-transition: 300ms ease 0s;
  -ms-transition: 300ms ease 0s;
  -o-transform: 300ms ease 0s;
  transition: 300ms ease 0s;
}
.noUi-handle:focus {
  outline: none;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.slider.slider-neutral .noUi-connect, .slider.slider-neutral.noUi-connect {
  background-color: #ffffff;
}
.slider.slider-neutral.noUi-target {
  background-color: rgba(255, 255, 255, 0.3);
}
.slider.slider-neutral .noUi-handle {
  background-color: #ffffff;
}
.slider.slider-primary .noUi-connect, .slider.slider-primary.noUi-connect {
  background-color: #d3d930;
}
.slider.slider-primary.noUi-target {
  background-color: rgba(249, 99, 50, 0.3);
}
.slider.slider-primary .noUi-handle {
  background-color: #d3d930;
}
.slider.slider-info .noUi-connect, .slider.slider-info.noUi-connect {
  background-color: #2ca8ff;
}
.slider.slider-info.noUi-target {
  background-color: rgba(44, 168, 255, 0.3);
}
.slider.slider-info .noUi-handle {
  background-color: #2ca8ff;
}
.slider.slider-success .noUi-connect, .slider.slider-success.noUi-connect {
  background-color: #598d30;
}
.slider.slider-success.noUi-target {
  background-color: rgba(24, 206, 15, 0.3);
}
.slider.slider-success .noUi-handle {
  background-color: #598d30;
}
.slider.slider-warning .noUi-connect, .slider.slider-warning.noUi-connect {
  background-color: #ffb236;
}
.slider.slider-warning.noUi-target {
  background-color: rgba(255, 178, 54, 0.3);
}
.slider.slider-warning .noUi-handle {
  background-color: #ffb236;
}
.slider.slider-danger .noUi-connect, .slider.slider-danger.noUi-connect {
  background-color: #ff3636;
}
.slider.slider-danger.noUi-target {
  background-color: rgba(255, 54, 54, 0.3);
}
.slider.slider-danger .noUi-handle {
  background-color: #ff3636;
}

.noUi-active {
  -webkit-transform: scale3d(1.5, 1.5, 1);
  -moz-transform: scale3d(1.5, 1.5, 1);
  -ms-transform: scale3d(1.5, 1.5, 1);
  -o-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
}

.section-isometricGrids {
  /* Header */
  /* Top Navigation Style */
  /* Demo links */
  /* Content */
  /* Related demos */
  /* Shadow effect */
  /* All individual isometric grid layouts (static and scrollable) */
  /* Shadow effect */
  /* All individual isometric grid layouts (static and scrollable) */
}
.section-isometricGrids .button-menu {
  width: 50px;
  height: 30px;
  position: fixed;
  top: 3em;
  right: 3em;
  border: none;
  background: transparent;
  fill: #fff;
  cursor: pointer;
  pointer-events: auto;
}
.section-isometricGrids .button-menu:focus {
  outline: none;
}
.section-isometricGrids .codrops-header {
  padding: 3vmax 0 0 3vmax;
  position: absolute;
  width: 100%;
  z-index: 2000;
  pointer-events: none;
}
.section-isometricGrids .demo-1 .codrops-header {
  position: fixed;
}
.section-isometricGrids .codrops-header h1 {
  margin: 0;
  letter-spacing: -0.035em;
  font-size: 4.65em;
  line-height: 1;
  font-weight: 900;
  color: #323F5C;
}
.section-isometricGrids .codrops-header p {
  padding: 0;
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
  color: #fff;
}
.section-isometricGrids .codrops-links {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  pointer-events: auto;
  margin: 0 0 0 -0.5em;
}
.section-isometricGrids .codrops-links::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 110%;
  background: #323F5C;
  opacity: 0.1;
  -webkit-transform: translateY(-50%) rotate3d(0, 0, 1, 22.5deg);
  transform: translateY(-50%) rotate3d(0, 0, 1, 22.5deg);
}
.section-isometricGrids .codrops-icon {
  display: inline-block;
  margin: 0.5em;
  width: 1.5em;
  text-decoration: none;
}
.section-isometricGrids .codrops-icon span {
  display: none;
}
.section-isometricGrids .codrops-icon::before {
  margin: 0 5px;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: "codropsicons";
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
.section-isometricGrids .codrops-icon--drop::before {
  content: "\e001";
  color: #0099cc;
}
.section-isometricGrids .codrops-icon--prev::before {
  content: "\e004";
}
.section-isometricGrids .codrops-demos {
  margin: 1.5em 0;
}
.section-isometricGrids .codrops-demos a {
  display: inline-block;
  margin: 0 1em 0 0;
  font-weight: bold;
  pointer-events: auto;
}
.section-isometricGrids .codrops-demos a.current-demo {
  color: #ce4555;
}
.section-isometricGrids .section--intro {
  position: relative;
  height: 75vh;
  max-height: 1200px;
}
.section-isometricGrids .section--right {
  text-align: right;
}
.section-isometricGrids .section__heading {
  pointer-events: none;
  padding: 0;
  font-size: 4.5em;
  line-height: 1;
  margin: 0;
  color: #fff;
  position: relative;
  z-index: 1000;
  font-family: "Playfair Display", serif;
}
.section-isometricGrids .section__heading em {
  font-family: sans-serif;
  font-style: normal;
  text-transform: uppercase;
  font-size: 0.3em;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  padding: 0.15em 0;
  vertical-align: middle;
  display: inline-block;
  letter-spacing: 0.05em;
}
.section-isometricGrids .section__subtitle {
  pointer-events: none;
  font-size: 1.25em;
  max-width: 500px;
  position: relative;
  z-index: 1000;
}
.section-isometricGrids .section--portraits .section__subtitle {
  color: #688CB1;
}
.section-isometricGrids .section--right .section__subtitle {
  margin: 1em 0 0 auto;
}
.section-isometricGrids .interval {
  position: relative;
  padding: 30vh 20vw;
  background: #1C222F;
  overflow: hidden;
}
.section-isometricGrids .interval__text {
  font-size: 1.75em;
  line-height: 1.5;
  color: #777792;
}
.section-isometricGrids .content--related {
  text-align: center;
  font-weight: bold;
  padding: 10vh 2vw;
  background: #1C222F;
  color: #fff;
}
.section-isometricGrids .media-item {
  display: inline-block;
  padding: 1em;
  vertical-align: top;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.section-isometricGrids .media-item__img {
  max-width: 100%;
  opacity: 0.3;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.section-isometricGrids .media-item:hover .media-item__img,
.section-isometricGrids .media-item:focus .media-item__img {
  opacity: 1;
}
.section-isometricGrids .media-item__title {
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
}
.section-isometricGrids .pater {
  position: fixed;
  right: 0;
  bottom: 0;
  color: #fff;
  width: 320px;
  padding: 2em 2em 2em 0;
  text-align: right;
  z-index: 100000;
}
.section-isometricGrids .demo-2 .pater {
  position: absolute;
}
.section-isometricGrids .pater::before {
  content: "";
  position: absolute;
  width: 491px;
  height: 332px;
  top: -80px;
  left: -60px;
  z-index: 0;
  opacity: 0.9;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
  -webkit-transition: opacity 0.3s, -webit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}
.section-isometricGrids .pater:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.section-isometricGrids .pater__title::before {
  content: "Sponsored by";
  position: absolute;
  bottom: 93%;
  left: 27%;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 0.45em;
  color: #212738;
}
.section-isometricGrids .pater__title,
.section-isometricGrids .pater__desc {
  margin: 0;
  color: #fff;
  position: relative;
}
.section-isometricGrids .pater__title {
  -webkit-transform: translate3d(5px, 10px, 0);
  transform: translate3d(5px, 10px, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.section-isometricGrids .pater:hover .pater__title {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.section-isometricGrids .pater__title img {
  width: 90%;
}
.section-isometricGrids .pater__desc {
  font-weight: 600;
  padding: 0.3em 0 0 0.3em;
}
@media screen and (max-width: 50em) {
  .section-isometricGrids .codrops-header {
    width: 80%;
  }
  .section-isometricGrids .codrops-header h1 {
    font-size: 2em;
  }
  .section-isometricGrids .codrops-header p {
    font-size: 0.85em;
  }
  .section-isometricGrids .interval {
    padding: 1.5em;
  }
  .section-isometricGrids .interval__text {
    font-size: 1.25em;
    padding: 0 0 3em 0;
  }
  .section-isometricGrids .section__heading {
    font-size: 2.5em;
  }
  .section-isometricGrids .section__subtitle {
    display: none;
  }
  .section-isometricGrids .codrops-demos {
    margin: 0.5em 0;
    font-size: 0.75em;
  }
  .section-isometricGrids .button-menu {
    top: 0.75em;
    right: 0.75em;
    width: 30px;
    height: 20px;
  }
  .section-isometricGrids .demo-1 .pater {
    width: 100%;
    padding: 1em;
    text-align: left;
    background: rgba(49, 63, 105, 0.8);
    -webkit-transform: translateZ(200px);
    transform: translateZ(200px);
  }
  .section-isometricGrids .demo-1 .pater__title::before {
    left: 0;
  }
  .section-isometricGrids .demo-1 .pater__title img {
    width: 150px;
    margin-top: 0.25em;
  }
  .section-isometricGrids .demo-1 .pater::before {
    display: none;
  }
  .section-isometricGrids .demo-1 .pater__desc {
    font-size: 0.65em;
  }
  .section-isometricGrids .isolayer.isolayer--scroll1 {
    top: -38vh;
  }
  .section-isometricGrids .demo-2 .pater {
    position: relative;
    display: block;
    background: none;
    margin: 0 0 0 auto;
    padding: 0;
    width: 100%;
  }
}
.section-isometricGrids .isolayer {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.section-isometricGrids .section--intro .grid,
.section-isometricGrids .section--intro .grid__item,
.section-isometricGrids .section--intro .grid__link {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.section-isometricGrids .grid {
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.section-isometricGrids .grid__item {
  width: 300px;
}
.section-isometricGrids .section--intro .grid__item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.section-isometricGrids .grid__link {
  position: relative;
  z-index: 1;
  display: block;
}
.section-isometricGrids .grid__img {
  display: block;
  max-width: 100%;
}
.section-isometricGrids .grid__title {
  font-size: 0.65em;
  font-weight: 600;
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0;
  color: #fff;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.section-isometricGrids .grid__item:hover .grid__title {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.section-isometricGrids .layer {
  position: relative;
  display: block;
}
.section-isometricGrids .layer:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}
.section-isometricGrids .isolayer--shadow .grid__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(-1px) scale(0.95);
  transform: translateZ(-1px) scale(0.95);
  -webkit-transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.section-isometricGrids .isolayer--shadow .grid__item:hover .grid__link::before {
  opacity: 0.2;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(-1px) scale(1);
  transform: translateZ(-1px) scale(1);
}
.section-isometricGrids .isolayer--scroll1 {
  width: 70vw;
  max-width: 1200px;
  height: calc(100vh - 280px);
}
.section-isometricGrids .isolayer--scroll1 .grid__item {
  width: 300px;
  padding: 15px;
}
.section-isometricGrids .pseudo-scroller {
  pointer-events: none;
}
.section-isometricGrids .isolayer {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.section-isometricGrids .grid {
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.section-isometricGrids .grid__item {
  width: 300px;
}
.section-isometricGrids .js .grid__item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.section-isometricGrids .grid__link {
  position: relative;
  z-index: 1;
  display: block;
}
.section-isometricGrids .grid__img {
  display: block;
  max-width: 100%;
}
.section-isometricGrids .grid__title {
  font-size: 0.65em;
  font-weight: 600;
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0;
  color: #fff;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.section-isometricGrids .grid__item:hover .grid__title {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.section-isometricGrids .layer {
  position: relative;
  display: block;
}
.section-isometricGrids .layer:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}
.section-isometricGrids .isolayer--shadow .grid__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(-1px) scale(0.95);
  transform: translateZ(-1px) scale(0.95);
  -webkit-transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.section-isometricGrids .isolayer--shadow .grid__item:hover .grid__link::before {
  opacity: 0.2;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(-1px) scale(1);
  transform: translateZ(-1px) scale(1);
}
.section-isometricGrids .isolayer--scroll1 {
  width: 70vw;
  max-width: 1200px;
  height: calc(100vh - 280px);
}
.section-isometricGrids .isolayer--scroll1 .grid__item {
  width: 300px;
  padding: 15px;
}
.section-isometricGrids .isolayer--deco1 {
  width: 1200px;
  height: 900px;
}
.section-isometricGrids .isolayer--deco1 .grid__link .layer:first-child:not(img) {
  background: #EB5E28;
}
.section-isometricGrids .isolayer--deco1 .grid__link .layer:nth-child(2):not(img) {
  background: #F3BB45;
}
.section-isometricGrids .isolayer--deco1 .grid__link .layer:nth-child(3):not(img) {
  background: #7AC29A;
}
.section-isometricGrids .isolayer--deco1 .grid__link div.layer {
  width: 370px;
  height: 270px;
}
.section-isometricGrids .isolayer--deco1 .grid__item {
  width: 400px;
  height: 300px;
  padding: 15px;
}
.section-isometricGrids .isolayer--deco1 .grid__item:nth-child(2) {
  margin-top: 135px;
}
.section-isometricGrids .isolayer--deco1 .grid__item:nth-child(6) {
  margin-top: 10px;
}
.section-isometricGrids .isolayer--deco2 {
  width: 1200px;
  height: 100vh;
}
.section-isometricGrids .isolayer--deco2 .grid__item {
  padding: 20px;
}
.section-isometricGrids .isolayer--deco2 .grid__link .layer {
  border: 20px solid #fff;
  border-width: 40px 20px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.08);
}
.section-isometricGrids .first-card {
  top: -20px !important;
}
.section-isometricGrids .first-card .layer:not(img) {
  height: 260px !important;
  width: 365px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .first-card img {
  border-radius: 6px;
}
.section-isometricGrids .second-card {
  top: 80px !important;
}
.section-isometricGrids .second-card .layer:not(img) {
  height: 170px !important;
  border-radius: 6px;
}
.section-isometricGrids .second-card img {
  border-radius: 6px;
}
.section-isometricGrids .third-card .layer:not(img) {
  height: 460px !important;
  width: 275px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .third-card {
  top: 290px !important;
}
.section-isometricGrids .third-card img {
  border-radius: 6px;
  width: 280px !important;
}
.section-isometricGrids .third-card .grid__link::before {
  top: 60px;
  height: 410px;
  width: 275px;
}
.section-isometricGrids .fourth-card {
  top: 270px !important;
}
.section-isometricGrids .fourth-card .layer:not(img) {
  height: 450px !important;
  width: 360px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .fourth-card img {
  border-radius: 6px;
}
.section-isometricGrids .fifth-card {
  top: 660px !important;
  left: 400px !important;
}
.section-isometricGrids .fifth-card .layer:not(img) {
  height: 400px !important;
  width: 364px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .fifth-card img {
  border-radius: 6px;
}
.section-isometricGrids .sixth-card {
  top: 485px !important;
}
.section-isometricGrids .sixth-card .layer:not(img) {
  height: 145px !important;
  width: 364px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .sixth-card img {
  border-radius: 6px;
}
.section-isometricGrids .seventh-card {
  top: 755px !important;
}
.section-isometricGrids .seventh-card .layer:not(img) {
  height: 435px !important;
  width: 365px !important;
  margin: 5px 0 0 5px;
  border-radius: 6px;
}
.section-isometricGrids .seventh-card img {
  border-radius: 6px;
}
.section-isometricGrids .eight-card {
  top: -215px !important;
  left: 400px !important;
}
.section-isometricGrids .eight-card img {
  border-radius: 6px;
}
.section-isometricGrids .eight-card .layer:not(img) {
  height: 480px !important;
  border-radius: 6px;
}
.section-isometricGrids .ninth-card {
  top: 790px !important;
  left: 800px !important;
}
.section-isometricGrids .ninth-card .grid__link::before {
  width: 280px !important;
  height: 370px !important;
}
.section-isometricGrids .ninth-card .layer:not(img) {
  width: 280px !important;
  height: 360px !important;
  border-radius: 6px;
}
.section-isometricGrids .ninth-card img {
  border-radius: 6px;
}
.section-isometricGrids .isolayer {
  transform: translateX(29vw) translateY(-377px) rotateX(50deg) rotateZ(26deg) !important;
  z-index: 1;
}

.selected-list .c-btn {
  box-shadow: none !important;
  border-radius: 30px !important;
  padding: 7px 18px 8px 18px !important;
  border: 1px solid #E3E3E3 !important;
}

.selected-list .c-list .c-token {
  background: none !important;
  color: #2c2c2c !important;
}

.cuppa-dropdown .dropdown-list {
  z-index: 1000 !important;
}
.cuppa-dropdown .dropdown-list .list-area {
  border: none !important;
  border-radius: 0.125rem !important;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2) !important;
}
.cuppa-dropdown .dropdown-list .list-area .list-filter input {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.cuppa-dropdown .dropdown-list .list-area .pure-checkbox label {
  width: 100%;
}
.cuppa-dropdown .dropdown-list .list-area .pure-checkbox input[type=checkbox]:checked + label:before {
  background: transparent !important;
  animation: none !important;
  right: 0 !important;
}
.cuppa-dropdown .dropdown-list .list-area .pure-checkbox input[type=checkbox] + label:before {
  border: none !important;
}
.cuppa-dropdown .dropdown-list .list-area .pure-checkbox input[type=checkbox] + label:after {
  left: auto !important;
  width: 14px !important;
  height: 7px !important;
  border-width: 0 0 1px 1px !important;
  right: 0 !important;
  opacity: 0.5 !important;
  border-color: #2c2c2c !important;
}
.cuppa-dropdown .dropdown-list .list-area ul li:last-child {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.cuppa-dropdown .dropdown-list .list-area ul li:first-child {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.cuppa-dropdown .dropdown-list .arrow-up {
  margin-left: 30px !important;
  border-bottom: 15px solid #fff;
}

.ng2-tag-input.regular-theme {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  cursor: text;
  border-bottom: none !important;
}
.ng2-tag-input.regular-theme form input {
  background: transparent;
}

.ng2-tag-input.regular-theme tag {
  display: inline-block;
  font-weight: 500;
  font-size: 0.75em;
  color: #FFF;
  border-radius: 12px;
  transition: all 170ms linear;
  margin: 10px 3px 5px 0;
  padding: 0px 0.8em 0px 0.8em;
  height: auto;
  max-height: 22px;
  line-height: 2.1em;
  background: #FF3636 !important;
  text-transform: uppercase;
  -webkit-transition: all 170ms linear;
  -moz-transition: all 170ms linear;
  -o-transition: all 170ms linear;
  -ms-transition: all 170ms linear;
  transition: all 170ms linear;
}
.ng2-tag-input.regular-theme tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background: #FF3636;
  color: #FFF;
}
.ng2-tag-input.regular-theme tag:hover {
  padding-right: 25px;
}
.ng2-tag-input.regular-theme tag:hover svg {
  opacity: 1;
}
.ng2-tag-input.regular-theme tag .tag-wrapper {
  display: block;
}

.ng2-tag-input.regular-theme tag delete-icon {
  text-align: right;
  transform: none;
}
.ng2-tag-input.regular-theme tag delete-icon path {
  fill: #FFF;
}
.ng2-tag-input.regular-theme tag delete-icon svg {
  vertical-align: middle;
  height: 13px;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 4px;
}
.ng2-tag-input.regular-theme tag delete-icon:not(.readonly):not(.tag--editing):focus {
  color: #FFF;
}
.ng2-tag-input.regular-theme tag delete-icon:not(.readonly):not(.tag--editing):active {
  color: #FFF;
}
.ng2-tag-input.regular-theme tag delete-icon:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  color: #FFF;
}

.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr;
}

.fileinput {
  display: inline-block;
  margin-bottom: 9px;
}

.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 360px;
}
.fileinput .thumbnail.img-circle {
  border-radius: 50%;
  max-width: 100px;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group {
  display: table;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}

.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ffb236;
}

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ffb236;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: #ff3636;
}

.form-group.has-error .fileinput .thumbnail {
  border-color: #ff3636;
}

.form-group.has-success .fileinput .fileinput-preview {
  color: #598d30;
}

.form-group.has-success .fileinput .thumbnail {
  border-color: #598d30;
}

.input-group-text:not(:first-child) {
  border-left: 0;
}

.thumbnail {
  border: 0 none;
  border-radius: 0;
  padding: 0;
}

.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action=incrementHours]::after,
.bootstrap-datetimepicker-widget .btn[data-action=incrementMinutes]::after,
.bootstrap-datetimepicker-widget .btn[data-action=decrementHours]::after,
.bootstrap-datetimepicker-widget .btn[data-action=decrementMinutes]::after,
.bootstrap-datetimepicker-widget .btn[data-action=showHours]::after,
.bootstrap-datetimepicker-widget .btn[data-action=showMinutes]::after,
.bootstrap-datetimepicker-widget .btn[data-action=togglePeriod]::after,
.bootstrap-datetimepicker-widget .btn[data-action=clear]::after,
.bootstrap-datetimepicker-widget .btn[data-action=today]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.bootstrap-datetimepicker-widget {
  list-style: none;
}

.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 8px 6px;
  width: 254px;
  max-width: 254px;
}
.bootstrap-datetimepicker-widget.dropdown-menu .now-ui-icons {
  opacity: 1;
  top: 2px;
}

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #ffffff;
  border-bottom: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  display: none;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: auto;
  bottom: -6px;
  right: auto;
  left: 10px;
  color: #ffffff;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: -20px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  border-width: 0;
  color: #66615B;
  background-color: transparent;
}

.bootstrap-datetimepicker-widget a[data-action=togglePicker],
.bootstrap-datetimepicker-widget a[data-action=togglePicker]:hover {
  color: #d3d930;
}

.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.5em;
  margin: 3px;
  border-radius: 50%;
}

.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  background-color: #d3d930;
  height: 38px;
  padding: 0;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.bootstrap-datetimepicker-widget .btn {
  margin: 0 !important;
}

.bootstrap-datetimepicker-widget .btn[data-action=incrementHours]::after {
  content: "Increment Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action=incrementMinutes]::after {
  content: "Increment Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action=decrementHours]::after {
  content: "Decrement Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action=decrementMinutes]::after {
  content: "Decrement Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action=showHours]::after {
  content: "Show Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action=showMinutes]::after {
  content: "Show Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action=togglePeriod]::after {
  content: "Toggle AM/PM";
}

.bootstrap-datetimepicker-widget .btn[data-action=clear]::after {
  content: "Clear the picker";
}

.bootstrap-datetimepicker-widget .btn[data-action=today]::after {
  content: "Set the date to today";
}

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
  color: #d3d930;
}

.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
  text-align: center;
}

.bootstrap-datetimepicker-widget table td > div, .bootstrap-datetimepicker-widget table th > div {
  text-align: center;
}

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 300;
}

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}

.bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table th.prev span, .bootstrap-datetimepicker-widget table th.next span {
  border-radius: 4px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  color: #d3d930;
}

.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}

.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}

.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  color: #d3d930;
  padding-bottom: 5px;
  padding-top: 10px;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span, .bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background: #eee;
}

.bootstrap-datetimepicker-widget table td.cw > div {
  font-size: 0.8em;
  height: 20px;
  line-height: 20px;
  color: #cfcfca;
}

.bootstrap-datetimepicker-widget table td.day > div,
.bootstrap-datetimepicker-widget table td.minute > div,
.bootstrap-datetimepicker-widget table td.hour > div {
  height: 30px;
  line-height: 2.2;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: -1;
  position: relative;
  font-weight: 300;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: all 300ms ease 0s;
}

.bootstrap-datetimepicker-widget table td.day:hover > div, .bootstrap-datetimepicker-widget table td.hour:hover > div, .bootstrap-datetimepicker-widget table td.minute:hover > div, .bootstrap-datetimepicker-widget table td.second:hover > div {
  background: #eee;
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table td.old > div, .bootstrap-datetimepicker-widget table td.new > div {
  color: #888888;
}

.bootstrap-datetimepicker-widget table td.today > div:before {
  content: "";
  display: inline-block;
  border: 0 0 7px 7px solid transparent;
  border-bottom-color: #68B3C8;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.bootstrap-datetimepicker-widget table td.active > div, .bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: #d3d930;
  color: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.bootstrap-datetimepicker-widget table td.active.today:before > div {
  border-bottom-color: #FFFFFF;
}

.bootstrap-datetimepicker-widget table td.disabled > div, .bootstrap-datetimepicker-widget table td.disabled:hover > div {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: #eee;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #d3d930;
  color: #FFFFFF;
}

.bootstrap-datetimepicker-widget table td span.old {
  color: #cfcfca;
}

.bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}

.input-group.date .input-group-text {
  cursor: pointer;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget .picker-switch,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table th.prev span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table th.next span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.day > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget a[data-action=togglePicker],
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget a[data-action=togglePicker]:hover,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget .timepicker-hours span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget .timepicker-minutes span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget .separator,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.minute > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.hour > div {
  color: #ffffff;
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table th.dow {
  color: rgba(255, 255, 255, 0.8);
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.old > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.new > div {
  color: rgba(255, 255, 255, 0.4);
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget button[data-action] {
  background-color: #ffffff;
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.active > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: #ffffff;
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td:not(.active).day:hover > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.hour:hover > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.minute:hover > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td.second:hover > div,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table td span:hover {
  background: rgba(255, 255, 255, 0.2);
}
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table thead tr:first-child th:hover span,
input.datetimepicker[data-color] + .bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

input.datetimepicker[data-color=orange] + ngb-datepicker {
  background-color: #d3d930;
}
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months .ngb-dp-day .btn-light,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover {
  color: #ffffff;
}
input.datetimepicker[data-color=orange] + ngb-datepicker:before {
  color: #d3d930;
}
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected=true] {
  background-color: #ffffff !important;
  color: #d3d930 !important;
}
input.datetimepicker[data-color=orange] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #ffffff !important;
}

input.datetimepicker[data-color=blue] + ngb-datepicker {
  background-color: #2ca8ff;
}
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months .ngb-dp-day .btn-light,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover {
  color: #ffffff;
}
input.datetimepicker[data-color=blue] + ngb-datepicker:before {
  color: #2ca8ff;
}
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected=true] {
  background-color: #ffffff !important;
  color: #2ca8ff !important;
}
input.datetimepicker[data-color=blue] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #ffffff !important;
}

input.datetimepicker[data-color=green] + ngb-datepicker {
  background-color: #598d30;
}
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months .ngb-dp-day .btn-light,
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover {
  color: #ffffff;
}
input.datetimepicker[data-color=green] + ngb-datepicker:before {
  color: #598d30;
}
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected=true] {
  background-color: #ffffff !important;
  color: #598d30 !important;
}
input.datetimepicker[data-color=green] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #ffffff !important;
}

input.datetimepicker[data-color=red] + ngb-datepicker {
  background-color: #ff3636;
}
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months .ngb-dp-day .btn-light,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover {
  color: #ffffff;
}
input.datetimepicker[data-color=red] + ngb-datepicker:before {
  color: #ff3636;
}
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected=true] {
  background-color: #ffffff !important;
  color: #ff3636 !important;
}
input.datetimepicker[data-color=red] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #ffffff !important;
}

input.datetimepicker[data-color=yellow] + ngb-datepicker {
  background-color: #ffb236;
}
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months .ngb-dp-day .btn-light,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-navigation-chevron,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light,
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover {
  color: #ffffff;
}
input.datetimepicker[data-color=yellow] + ngb-datepicker:before {
  color: #ffb236;
}
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view div[ng-reflect-selected=true] {
  background-color: #ffffff !important;
  color: #ffb236 !important;
}
input.datetimepicker[data-color=yellow] + ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #ffffff !important;
}

ngb-datepicker {
  border: 0 none !important;
  display: block !important;
  color: #888888;
  padding: 8px 6px;
  position: absolute !important;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem !important;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear !important;
}
ngb-datepicker.dropdown-menu {
  top: 100% !important;
}
ngb-datepicker.dropdown-menu:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -7px;
  left: 10px;
  right: auto;
  border-bottom: 0.4rem solid;
  border-right: 0.41rem solid transparent;
  border-left: 0.4rem solid transparent;
}
ngb-datepicker .bg-light {
  background: transparent !important;
}
ngb-datepicker:focus {
  outline: none !important;
}
ngb-datepicker .ngb-dp-header {
  border-bottom: none !important;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation {
  color: #d3d930;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow-btn .ngb-dp-navigation-chevron {
  color: #d3d930;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select {
  font-weight: 300;
  color: #d3d930;
  border: none !important;
  background: transparent !important;
  -webkit-appearance: menulist !important;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week {
  border: none;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday {
  color: #d3d930;
  padding-top: 10px;
  opacity: 0.8;
  font-style: normal !important;
  line-height: 20px;
  font-weight: 300;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .small {
  font-size: 14px;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light {
  border-radius: 50% !important;
  color: #2c2c2c;
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover, ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:active {
  transition: all 150ms linear 0s;
  color: #2c2c2c;
  background-color: rgba(222, 222, 222, 0.3);
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day div[ng-reflect-selected=true] {
  background-color: #d3d930;
  color: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
ngb-datepicker .ngb-dp-months ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .text-muted {
  color: #888888;
}