
.mat-table {
  overflow-x: scroll;
}

app-pin-modal {
  display: flex;
  justify-content: center;
}

.btn {
  color: #fff !important;
  border-width: 2px;
  font-weight: 400;
  font-size: 0.8571em;
  line-height: 1.35em;
  margin: 5px 1px;
  border: none;
  margin: 10px 1px;
  border-radius: 0.1875rem;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #888888;
}

.btn-primary {
  background-color: #d3d930 !important;
  color: #ffffff;
  box-shadow: none !important;
  border: none !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #d3d930 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #d3d930 !important;   
}

.success-snackbar,
.error-snackbar {
  all: initial;
  color: white !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.success-snackbar {
  background-color: #d3d930 !important;
}

.error-snackbar {
  background-color: rgba(#b00e20, 0.75) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d3d930 !important;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #d3d930 !important;
}

.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #d3d930 !important;
}

.tooltip .tooltip-inner {
    background-color: #d3d930 !important;
    color: #ffffff !important;
    margin-left: 10px;
    font-weight: bold !important;
    opacity: 1 !important;
    z-index: 2000 !important;
}

.mat-dialog-container {
    background-color: transparent !important;
    box-shadow: none !important;
}

.tooltip.show {
    opacity: 1 !important;
    z-index: 2000 !important;
}

.card-plain {

    .card-header:hover {
        // background-color: #b8b8b82e;
    }
    .card-header {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.row {
    --bs-gutter-x: 0 !important;
}

//generic css
:root {
    --top-res-circle-open: 22rem;
    --top-cli-circle-open: 15rem;
    color-scheme: light !important;
}

.modal-content {
    min-height: 90vh !important;
    overflow-y: auto;
}

.font-picker {
    border-radius: 4px;
    border-color: #00000052 !important;
}

.twitter-body {
    padding: 15px 9px 15px 15px !important;
}

.twitter-input {
    display: none !important;
}

.twitter-hash {
    display: none !important;
}

.carousel .carousel-inner {
    box-shadow: none !important;
}

.mat-select-search-input:focus {
    outline: none;
}

.mat-select-search-input {
    outline: none;
    border: none;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #000 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #d3d930 !important;
}

.mat-primary .mat-pseudo-checkbox-checked {
    background: #d3d930 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #d3d930 !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #d3d930 !important;
}

.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #d3d930 !important;
}

.mat-option.mat-active {
    background-color: transparent !important;
}

.mat-select-value {
    display: block !important;
    max-width: 100% !important;
    width: 100% !important;
    overflow: inherit !important;
}

.mat-select-value-text {
    white-space: break-spaces !important;
    overflow: inherit !important;
    text-overflow: clip !important;
    font-size: 0.9rem !important;
    font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
}

.mat-select-trigger {
    display: flex !important;
    position: inherit !important;
    height: 100% !important;
}

.mat-select-arrow {
    margin: 0 auto !important;
}

.mat-select-arrow-wrapper {
    display: block !important;
}

.mat-select {
    display: block !important;
    margin-top: 5px !important;
}

.timepicker {
    .timepicker__header {
        background-color: #d3d930 !important;
    }

    .clock-face__clock-hand {
        background-color: #d3d930 !important;

        &::after {
            background-color: #d3d930 !important;
        }

        &::before {
            border-color: #d3d930 !important;
        }
    }

    .clock-face__number {
        >span.active {
            background-color: #d3d930 !important;
        }
    }

    button:not(.timepicker-dial__item) {
        color: #d3d930 !important;
    }

    .timepicker-dial__control:focus {
        outline: none;
    }
}

// Theming for the multi-select
@import '~@ng-select/ng-select/themes/default.theme.css';

// MultiSelect Styling
.ng-select.multiselect {
    background-color: transparent;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 30px;
    border-left: 0 none;
    color: #2c2c2c;
    height: unset;
    line-height: normal;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    display: block;
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ng-select.multiselect:target {
    border-color: #fff !important;
}

.ng-select.ng-select-focused {
    border-color: #fff !important;
}

.ng-select.multiselect .ng-select-container {
    background-color: transparent;
    border: none;
    color: #2c2c2c;
    line-height: normal;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    position: relative;
    flex: 1 1 auto;
    min-width: 100%;
    margin-bottom: 0;
    max-width: fit-content;
}

.ng-dropdown-panel.ng-select-bottom {
    margin-left: -17px !important;
}

.ng-dropdown-panel {
    background: #ff99734d !important;
    //box-shadow: 0px 2px 20px 5px #ffffff70 !important;
    border: 1px solid #fe7a4b !important;
    border-top-color: transparent !important;
}

.ng-dropdown-panel-items {
    background-color: #efefef14 !important;
    //box-shadow: 1px 1px 6px 0px #ffffff69;
    border-radius: 30px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fe7a4b;
    margin: 10px;
    color: #fff !important;
    border-radius: 25px;
}

.ng-option-marked {
    color: #fff !important;
    background-color: #fa6731 !important;
}

.ng-option-selected {
    color: #fff !important;
    background-color: #fa6731 !important;
}

.ng-placeholder {
    font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
    font-size: 1.19em !important;
    color: #ffffff !important;
    top: 10px !important;
    padding-bottom: 0px !important;
    padding-left: 13px !important;
}

.ng-arrow {
    border-color: #fff transparent transparent !important;
}

.ng-clear {
    color: #fff !important;
}

.ng-value {
    background-color: #fe7a4b !important;
    color: #fff;
    font-size: 1.5em !important;
}

.ng-option:last-child {
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
}

.ng-value-icon:hover {
    background-color: #f96731 !important;
}

.ng-input {
    input {
        color: white !important;
    }
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

// Plus imports for other components in your app.
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #fff !important;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$GLOBAL-NextGenMenu-Frontend-Web-primary: mat-palette($mat-indigo);
$GLOBAL-NextGenMenu-Frontend-Web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$GLOBAL-NextGenMenu-Frontend-Web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$GLOBAL-NextGenMenu-Frontend-Web-theme: mat-light-theme((color: (primary: $GLOBAL-NextGenMenu-Frontend-Web-primary,
            accent: $GLOBAL-NextGenMenu-Frontend-Web-accent,
            warn: $GLOBAL-NextGenMenu-Frontend-Web-warn,
        ),
    ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($GLOBAL-NextGenMenu-Frontend-Web-theme);
/* You can add global styles to this file, and also import other style files */
@import '~aos/dist/aos.css';

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
 

// html, body { height: 100%; }
html, body { margin: 0; font-family: 'Montserrat', sans-serif !important; }


//Background-color
.bg-blueNight {
  background-color: #0E2B4D !important;
}
.bg-orange{
  background-color: #F25116;
}
.bg-gray{
  background-color: #F1F1F1;
}
.bg-dark-gray {
  background-color: #363636;
}
.bg-light-gray{
  background: #535353;
}
.bg-green {
  background-color: #AABF23;
}
.bg-green-dark {
  background-color: #5B8C2A;
}
.bg-white {
  background-color: #F1F1F1;
}
#bg-blueNight {
  background-color: #0E2B4D !important;
}
#bg-orange{
  background-color: #F25116 !important;
}
#bg-yellow{
  background-color: #FFDA17 !important;
}
#bg-green{
  background-color: #5B8C2A !important;
}


// Border button
.border-color-green {
  border: 2px solid #5B8C2A !important;
}
// Font-size em
.f-18 {
  font-size: 18px;
}
.f-35{
  font-size: 35px !important;
}
.f-64{
  font-size: 64px !important;
}
.f1em {
  font-size: 1em !important;
}
.f1-2em{
  font-size: 1.2em;
}
.f1-6em {
  font-size: 1.6em;
}
.f3em {
  font-size: 3em !important;
}

// Font-size px
.f14-24px {
  font-size: calc(0.83rem + 0.2vw);
}
.f14-20px {
  font-size: calc(0.83rem + 0.2vw);
}

// Font-weight
.fw-800 {
  font-weight: 800 !important;
}
.fw-700{
  font-weight: 700 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
// Line Height
.line-height-1-9{
  line-height: 1.9em;
}
// Overflow-y
.overflow-y {
  overflow-y: scroll;
}
// Text-color
.t-orange{
  color: #F25116;
}
.t-light-orange{
  color:  #E77C40;
}
.t-green {
  color: #5B8C2A;
}
.t-white {
  color: #F1F1F1;
}
.t-gray {
  color: #5E6366;
}
.t-dark-gray {
  color: #5E5D5C;
}
.t-light-gray {
  color: #737373;
}
.t-azure{
  color: #23A6F0;
}

html {
    scroll-behavior: smooth !important;
}


.tooltip-z-index {
    z-index: 100;
}
 

